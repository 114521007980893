import { ArrowClockwise } from "react-bootstrap-icons";

interface RefreshIconProps {
  onClick: () => void | Promise<void>;
}

export const RefreshIcon = ({ onClick }: RefreshIconProps) => (
  <div onClick={onClick}>
    <ArrowClockwise />
  </div>
);
