import { Alert } from "react-bootstrap";

import { usePerformanceStoreHook } from "../../hooks";
import { StyledTd, TableContainer } from "../misc/TableContainer";
import { RefreshIcon } from "../RefreshIcon";

const PriceStoreViewer = () => {
  const {
    performanceStore,
    hasError,
    isLoading,
    countdown,
    fetchPerformanceStore,
  } = usePerformanceStoreHook();

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  const tradeCountSum = performanceStore.reduce(
    (accumulator, currentValue) => accumulator + currentValue.tradeCount,
    0,
  );

  const volumeSum = performanceStore.reduce(
    (accumulator, currentValue) => accumulator + (currentValue.volume || 0),
    0,
  );

  const profitSum = performanceStore.reduce(
    (accumulator, currentValue) => accumulator + currentValue.profitInDollars,
    0,
  );

  return (
    <TableContainer
      header={[
        <div key={"Asset"} className="d-flex align-items-center">
          Name&nbsp;
          <RefreshIcon onClick={fetchPerformanceStore} />
        </div>,
        "Trades",
        "Volume",
        "+ / - (+%)",
        "Fail",
        "Profit $",
        "Ø profit / trade",
      ].filter((value) => value)}
      isEmpty={!performanceStore.length}
      isLoading={isLoading}
      countdown={countdown}
    >
      {performanceStore.map((performanceInfo) => (
        <tr key={performanceInfo.arbitrageName}>
          <StyledTd>{performanceInfo.arbitrageName}</StyledTd>
          <StyledTd>{performanceInfo.tradeCount}</StyledTd>
          <StyledTd>{performanceInfo.volume}</StyledTd>
          <StyledTd>
            <span className="text-success">
              {performanceInfo.tradeCount - performanceInfo.negativeTradeCount}
            </span>
            &nbsp;/&nbsp;
            <span className="text-danger">
              {performanceInfo.negativeTradeCount}
            </span>
            &nbsp;
            <span>
              (
              {(
                (100 *
                  (performanceInfo.tradeCount -
                    performanceInfo.negativeTradeCount)) /
                performanceInfo.tradeCount
              ).toFixed(2)}
              %)
            </span>
          </StyledTd>
          <StyledTd>{performanceInfo.failedTradeCount}</StyledTd>
          <StyledTd>{performanceInfo.profitInDollars.toFixed(2)}</StyledTd>
          <StyledTd>
            {performanceInfo.averageProfitInDollarsPerTrade.toFixed(2)}
          </StyledTd>
        </tr>
      ))}
      {performanceStore.length ? (
        <tr style={{ borderTop: "4px double #dee2e64d" }}>
          <td className="fw-bold">Total</td>
          <td>{tradeCountSum}</td>
          <td>{volumeSum.toFixed(2)}</td>
          <td></td>
          <td></td>
          <td>{profitSum.toFixed(2)}</td>
          <td>{(profitSum / tradeCountSum).toFixed(2)}</td>
        </tr>
      ) : null}
    </TableContainer>
  );
};

export default PriceStoreViewer;
