import useBaseGetHook from "./useBaseGetHook";
import useBasePostHook from "./useBasePostHook";
import type { UserRequest, UserResponse } from "../types/Api";

const useUsersHook = () => {
  const {
    items: users,
    isLoading,
    hasError,
    fetchItems: fetchUsers,
  } = useBaseGetHook<UserResponse[]>("user");

  const {
    postItem,
    id: blockingId,
    isLoading: isBlocking,
    isLoadingDone: isBlockingDone,
    hasError: hasBlockingError,
  } = useBasePostHook<UserRequest>();

  const blockUser = async (data: UserRequest) => {
    await postItem(data, data.id, `user/${data.id}`);
    fetchUsers();
  };

  return {
    users,
    isLoading,
    hasError,
    fetchUsers,
    blockUser,
    isBlocking,
    blockingId,
    isBlockingDone,
    hasBlockingError,
  };
};

export default useUsersHook;
