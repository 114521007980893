import { useEffect } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { GasPriceResponse } from "../types/Api";

const useGasPrice = () => {
  const {
    items: gasPrices,
    isLoading,
    hasError,
    fetchItems: fetchGasPrice,
    countdown,
  } = useBaseGetHook<GasPriceResponse[]>(`gasprice`, undefined, 4);

  useEffect(() => {
    fetchGasPrice();
  }, []);

  return {
    gasPrices,
    isLoading,
    hasError,
    countdown,
  };
};

export default useGasPrice;
