import { Alert, Card, Form } from "react-bootstrap";
import { Trash2 } from "react-bootstrap-icons";
import styled from "styled-components";

import { LOCAL_STORAGE_PAGINATION } from "../../constants";
import { useLogs } from "../../hooks";
import { LogLevel } from "../../hooks/useLogsHook";
import { LogResponse } from "../../types/Api";
import { formatDateTime } from "../../utils";
import { TableContainer } from "../misc/TableContainer";

const StyledFormCheck = styled(Form.Check)`
  width: 100%;
  &:not(:last-of-type) {
    padding-right: 21px;
  }
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0;
  user-select: none;

  label {
    padding: 10px;
    margin: -10px;
  }
`;

const StyledDiv = styled.div`
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  &:hover {
    text-decoration: underline;
  }
`;

const getLogLevelColor = (level: LogResponse["level"]) => {
  switch (level) {
    case LogLevel.Information:
      return "text-muted";
    case LogLevel.Warning:
      return "text-info";
    case LogLevel.Error:
      return "text-warning";
    case LogLevel.Fatal:
      return "text-danger";
  }
};

const LogsTable = () => {
  const {
    logs,
    isLoading,
    hasError,
    countdown,
    pageNumber,
    setPageNumber,
    deleteLogs,
    levels,
    setLevels,
  } = useLogs();

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  return (
    <>
      <Card className="mb-2 d-inline-flex">
        <Card.Body className="p-2 px-3">
          <div className="d-flex align-items-center">
            <StyledDiv
              onClick={() =>
                setLevels(
                  Object.keys(LogLevel).map(
                    (key) => LogLevel[key as keyof typeof LogLevel],
                  ),
                )
              }
            >
              Select all
            </StyledDiv>
            &nbsp;/&nbsp;
            <StyledDiv onClick={() => setLevels([])}>Unselect all</StyledDiv>
          </div>
          <div className="d-flex align-items-center">
            {Object.keys(LogLevel).map((levelKey) => {
              const levelValue = LogLevel[levelKey as keyof typeof LogLevel];

              return (
                <StyledFormCheck
                  key={levelKey}
                  type="checkbox"
                  label={levelValue}
                  id={levelValue}
                  checked={levels.includes(levelValue)}
                  onChange={() => {
                    const index = levels.findIndex((lvl) => lvl === levelValue);

                    if (index === -1) {
                      setLevels([...levels, levelValue]);
                    } else {
                      setLevels(levels.filter((lvl) => lvl !== levelValue));
                    }
                  }}
                />
              );
            })}
          </div>
        </Card.Body>
      </Card>

      <TableContainer
        isLoading={isLoading}
        countdown={countdown}
        header={[
          [
            <div key="trash" className="align-items-center">
              Created&nbsp;
              <Trash2 onClick={() => deleteLogs()} className="mb-1" />
            </div>,
            "130px",
          ],
          ["Source", "65px"],
          ["Level", "70px"],
          ["Error message", "600px"],
        ]}
        isEmpty={!logs.items?.length}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        totalPages={logs.totalPages}
        currentPageNumber={logs.pageNumber}
        paginationName={LOCAL_STORAGE_PAGINATION.logs}
      >
        {logs.items?.map((log, index) => (
          <tr key={index}>
            <td>{formatDateTime(log.created!)}</td>
            <td>{log.source}</td>
            <td
              className={`${getLogLevelColor(log.level)} cursor-pointer`}
              onClick={() => alert(log.exception)}
            >
              {log.level}
            </td>
            <td>{log.message}</td>
          </tr>
        ))}
      </TableContainer>
    </>
  );
};

export default LogsTable;
