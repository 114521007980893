import ExchangeCard from "./ExchangeCard";
import ExchangeTable from "./ExchangeTable";
import { useCurrentBalances } from "../../hooks";
import BalanceCurrent from "../balance/BalanceCurrent";

const ExchangeViewer = () => {
  const { currentBalanceGroups, isLoading, hasError, fetchItems, countdown } =
    useCurrentBalances();

  return (
    <>
      <BalanceCurrent
        currentBalanceGroups={currentBalanceGroups}
        isLoading={isLoading}
        fetchCurrentBalances={fetchItems}
        hasError={hasError}
        countdown={countdown}
      />
      <ExchangeCard refreshData={fetchItems} />
      <ExchangeTable />
    </>
  );
};

export default ExchangeViewer;
