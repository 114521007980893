import { useContext, useEffect, useMemo } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { LOCAL_STORAGE_PAGINATION } from "../constants";
import { DataContext } from "../context/Data";
import { usePagination } from "../hooks";
import type { TradeResponse } from "../types/Api";

const useTradeData = () => {
  const { fromDate, toDate, chosenWallet, chosenArbitrage } =
    useContext(DataContext);

  const { pageNumber, setPageNumber } = usePagination();

  const params = useMemo(
    () =>
      new URLSearchParams({
        from: fromDate,
        to: toDate,
        walletId: chosenWallet?.id.toString() || "",
        arbitrageId: chosenArbitrage?.id?.toString() || "",
        pageSize:
          localStorage.getItem(LOCAL_STORAGE_PAGINATION.trades) || "1000",
        pageNumber: pageNumber.toString(),
      }),
    [fromDate, toDate, chosenWallet, chosenArbitrage, pageNumber],
  );

  const {
    items: tradeData,
    isLoading,
    hasError,
    fetchItems: fetchTrades,
    countdown,
  } = useBaseGetHook<TradeResponse>(
    `trade?${params.toString()}`,
    undefined,
    10,
  );

  useEffect(() => {
    fetchTrades();
  }, [fromDate, toDate, chosenWallet, chosenArbitrage, pageNumber]);

  return {
    tradeData,
    isLoading,
    hasError,
    pageNumber,
    setPageNumber,
    countdown,
  };
};

export default useTradeData;
