import { formatDistance } from "date-fns";
import { useEffect, useMemo } from "react";
import { Alert } from "react-bootstrap";

import { useGatewayInfo } from "../../hooks";
import { WithdrawalDepositGatewayScanResponse } from "../../types/Api";
import { findNetworkById, getTimezoneOffset } from "../../utils";
import { StyledTd, TableContainer } from "../misc/TableContainer";

const getStatusIndicator = (status: boolean, updatedTime: string) => {
  const isRecent =
    new Date(updatedTime).getTime() - getTimezoneOffset() + 180000 < Date.now();
  return status ? (isRecent ? "⚠️" : "✅") : "❌";
};

const formatDateDistance = (date: string) =>
  formatDistance(Number(new Date(date)) - getTimezoneOffset(), new Date()) +
  " ago";

const GatewayRow = ({
  gateway,
}: {
  gateway: WithdrawalDepositGatewayScanResponse;
}) => {
  const depositStatus = getStatusIndicator(
    gateway?.depositStatus,
    gateway.updated,
  );
  const withdrawStatus = getStatusIndicator(
    gateway?.withdrawStatus,
    gateway.updated,
  );
  const quantityDisplay = gateway.asset.isStableCoin
    ? gateway.availableQuantity.toFixed(0)
    : gateway.availableQuantity.toFixed(gateway.asset.textDecimals);

  return (
    <tr>
      <StyledTd>{gateway.asset.name}</StyledTd>
      <StyledTd>{findNetworkById(gateway.networkPlatform)}</StyledTd>
      <StyledTd>{gateway.exchangeTypeAbbreviation}</StyledTd>
      <StyledTd>{quantityDisplay}</StyledTd>
      <StyledTd>
        {depositStatus}&nbsp;&nbsp;
        {gateway.transactionInCountProcessedInLastHour}
      </StyledTd>
      <StyledTd>
        {withdrawStatus}&nbsp;&nbsp;
        {gateway.transactionOutCountProcessedInLastHour}
      </StyledTd>
      <StyledTd>
        {formatDateDistance(gateway.availableQuantityUpdated)}
      </StyledTd>
      <StyledTd>{formatDateDistance(gateway.updated)}</StyledTd>
      <StyledTd>
        {gateway.link && (
          <a href={gateway.link} target="_blank" rel="noreferrer">
            Link
          </a>
        )}
      </StyledTd>
    </tr>
  );
};

const GatewaysStatus = () => {
  const {
    fetchGatewayDetails,
    gatewayDetails,
    isLoading,
    hasError,
    countdown,
  } = useGatewayInfo();

  useEffect(() => {
    fetchGatewayDetails();
  }, []);

  const sortedGatewayDetails = useMemo(
    () =>
      gatewayDetails?.sort((a, b) =>
        a.asset.name.localeCompare(b.asset.name),
      ) || [],
    [gatewayDetails],
  );

  if (hasError || !gatewayDetails) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  return (
    <TableContainer
      header={[
        "Asset",
        "Network",
        "Exchange",
        "Quantity",
        "In txs",
        "Out txs",
        ["Amount updated", "200px"],
        ["Info updated", "160px"],
        "Link",
      ]}
      isEmpty={!sortedGatewayDetails.length}
      countdown={countdown}
      isLoading={isLoading}
    >
      {sortedGatewayDetails.map((gateway, index) => (
        <GatewayRow key={index} gateway={gateway} />
      ))}
    </TableContainer>
  );
};

export default GatewaysStatus;
