import { useContext, useEffect, useMemo } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { DataContext } from "../context/Data";
import { BalanceResponse } from "../types/Api";

const useBalanceHook = () => {
  const { fromDate, toDate, chosenWallet, chosenArbitrage } =
    useContext(DataContext);

  const params = useMemo(
    () =>
      new URLSearchParams({
        from: fromDate,
        to: toDate,
        walletId: chosenWallet?.id.toString() || "",
        arbitrageId: chosenArbitrage?.id?.toString() || "",
      }),
    [fromDate, toDate, chosenWallet, chosenArbitrage],
  );

  const {
    items: balance,
    isLoading,
    hasError,
    fetchItems: fetchBalance,
    countdown,
  } = useBaseGetHook<BalanceResponse>(
    `balance?${params.toString()}`,
    undefined,
    5,
  );

  useEffect(() => {
    fetchBalance();
  }, [fromDate, toDate, chosenWallet, chosenArbitrage]);

  return { balance, isLoading, hasError, countdown };
};

export default useBalanceHook;
