import useBaseGetHook from "./useBaseGetHook";
import useBasePostHook from "./useBasePostHook";
import type {
  SwapPriceResponse,
  SwapPricesRequest,
  SwapRequest,
  SwapWhitelistResponse,
} from "../types/Api";

const useExchange = () => {
  const {
    postItem: exchange,
    isLoading,
    isLoadingDone,
    hasError,
  } = useBasePostHook<SwapRequest>("swap");

  const {
    items: prices,
    setItems: setPrices,
    isLoading: pricesIsLoading,
    hasError: pricesHasError,
    fetchItems,
  } = useBaseGetHook<SwapPriceResponse>(undefined, false);

  const getExchangePrices = (
    assetPairId: SwapPricesRequest["assetPairId"],
    walletId: SwapPricesRequest["walletId"],
    amount: SwapPricesRequest["amount"],
  ) => {
    const params = new URLSearchParams({
      walletId: walletId.toString(),
      assetPairId: assetPairId.toString(),
      amount: amount.toString(),
    });

    fetchItems(`swap/prices?${params.toString()}`);
  };

  const resetPrices = () => {
    // @ts-ignore
    setPrices();
  };

  const {
    items: exchangeWhitelist,
    isLoading: exchangeWhitelistIsLoading,
    hasError: exchangeWhitelistHasError,
    fetchItems: fetchExchangeWhitelist,
  } = useBaseGetHook<SwapWhitelistResponse[]>("swap/whitelist");

  return {
    exchange,
    isLoadingDone,
    isLoading,
    hasError,
    getExchangePrices,
    prices,
    pricesIsLoading,
    pricesHasError,
    resetPrices,
    fetchExchangeWhitelist,
    exchangeWhitelist,
    exchangeWhitelistIsLoading,
    exchangeWhitelistHasError,
  };
};

export default useExchange;
