import { useEffect, useState } from "react";

import { BACKEND_API_URL } from "../constants";
import { BaseMessage } from "../types/Api";

import { useFetch } from ".";

const useBasePutHook = <T>(pathname?: string) => {
  const [id, setId] = useState<number | undefined>();
  const [isLoadingDone, setIsLoadingDone] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>("");

  const { fetch, abortController } = useFetch();

  const updateItem = async (
    data: T,
    id?: number,
    pathnameFallback?: string,
  ) => {
    if (!pathname && !pathnameFallback) {
      console.error("Missing pathname");
      return;
    }
    setIsLoading(true);
    setIsLoadingDone("");
    setHasError("");
    setId(id);

    try {
      const result = await fetch(
        `${BACKEND_API_URL}/${pathname || pathnameFallback}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
        },
      );

      if (result.ok) {
        try {
          const response: BaseMessage = await result.json();
          setIsLoadingDone(response?.message || "Done");
        } catch (e) {
          setIsLoadingDone("Done");
        }
      } else {
        const parsedError = await result.json();

        setHasError(parsedError?.message || `/${pathname || pathnameFallback}`);
        setIsLoadingDone("");
      }
    } catch (error) {
      setHasError(`/${pathname || pathnameFallback} - ${error}`);
      setIsLoadingDone("");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => () => abortController.abort(), []);

  return {
    updateItem,
    id,
    isLoading,
    isLoadingDone,
    hasError,
  };
};

export default useBasePutHook;
