import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  roundToNearestMinutes,
  startOfMonth,
  startOfToday,
  startOfTomorrow,
  startOfWeek,
  subDays,
  subHours,
  subMinutes,
  subMonths,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import styled from "styled-components";

import { LOCAL_STORAGE_SETTINGS_COLLAPSED } from "../../constants";
import { DataContext } from "../../context/Data";
import { useGasPrice, useInstanceTypeStatus, useUserStatus } from "../../hooks";
import { InstanceTypeStatus } from "../../types/Api";
import {
  findInstanceById,
  findNetworkById,
  getIsoDate,
  getTodayUTC,
  timeZone,
} from "../../utils";

const StyledInputGroupText = styled(InputGroup.Text)`
  width: 3.5rem;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  flex-wrap: wrap;
`;

const BadgeWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledBadge = styled(Badge)`
  display: inline-grid;
  text-align: left;
`;

const SizedSpan = styled.span`
  width: 12px;
  display: inline-block;
`;

const DATETIME_SETTINGS_LABEL = "Date & Time";

const dateOptions = [
  {
    label: "Last 10 minutes",
    onClickFromDate: zonedTimeToUtc(subMinutes(new Date(), 10), timeZone),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "Last 30 minutes",
    onClickFromDate: zonedTimeToUtc(subMinutes(new Date(), 30), timeZone),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "Last hour",
    onClickFromDate: zonedTimeToUtc(subHours(new Date(), 1), timeZone),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "Last 3 hours",
    onClickFromDate: zonedTimeToUtc(subHours(new Date(), 3), timeZone),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "Last 6 hours",
    onClickFromDate: zonedTimeToUtc(subHours(new Date(), 6), timeZone),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "Last 12 hours",
    onClickFromDate: zonedTimeToUtc(subHours(new Date(), 12), timeZone),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "Today",
    onClickFromDate: getTodayUTC(),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "Yesterday",
    onClickFromDate: subDays(getTodayUTC(), 1),
    onClickToDate: getTodayUTC(),
  },
  {
    label: "Last 24 hours",
    onClickFromDate: zonedTimeToUtc(subHours(new Date(), 24), timeZone),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "Last 3 days",
    onClickFromDate: zonedTimeToUtc(subHours(new Date(), 72), timeZone),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "Last 7 days",
    onClickFromDate: zonedTimeToUtc(subHours(new Date(), 168), timeZone),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "This week",
    onClickFromDate: zonedTimeToUtc(
      startOfWeek(getTodayUTC(), { weekStartsOn: 1 }),
      "UTC",
    ),
    onClickToDate: zonedTimeToUtc(
      endOfWeek(getTodayUTC(), { weekStartsOn: 1 }),
      "UTC",
    ),
  },
  {
    label: "Previous week",
    onClickFromDate: zonedTimeToUtc(
      subDays(startOfWeek(getTodayUTC(), { weekStartsOn: 1 }), 7),
      "UTC",
    ),
    onClickToDate: zonedTimeToUtc(
      subDays(endOfWeek(getTodayUTC(), { weekStartsOn: 1 }), 7),
      "UTC",
    ),
  },
  {
    label: "This month",
    onClickFromDate: zonedTimeToUtc(startOfMonth(new Date()), "UTC"),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "Previous month",
    onClickFromDate: zonedTimeToUtc(
      subMonths(startOfMonth(getTodayUTC()), 1),
      "UTC",
    ),
    onClickToDate: zonedTimeToUtc(
      endOfMonth(subMonths(getTodayUTC(), 1)),
      "UTC",
    ),
  },
  {
    label: "Last month",
    onClickFromDate: zonedTimeToUtc(subMonths(new Date(), 1), timeZone),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
  {
    label: "All time",
    onClickFromDate: zonedTimeToUtc(new Date("2021-05-06 23:41:54"), "UTC"),
    onClickToDate: addDays(getTodayUTC(), 1),
  },
];

const Settings = () => {
  const {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    wallets,
    chosenWallet,
    setChosenWallet,
    arbitrages,
    chosenArbitrage,
    setChosenArbitrage,
  } = useContext(DataContext);

  const [collapsed, setCollapsed] = useState<boolean>(
    !!localStorage.getItem(LOCAL_STORAGE_SETTINGS_COLLAPSED),
  );

  const [pickerFromDate, setPickerFromDate] = useState(fromDate);
  const [pickerToDate, setPickerToDate] = useState(toDate);
  const [datetimeSettingsLabel, setDatetimeSettingsLabel] = useState<string>(
    DATETIME_SETTINGS_LABEL,
  );

  const { instanceTypeInfo } = useInstanceTypeStatus();
  const { gasPrices } = useGasPrice();
  const { userStatuses } = useUserStatus();

  useEffect(() => {
    setPickerFromDate(fromDate);
    setPickerToDate(toDate);
  }, [fromDate, toDate]);

  return (
    <Card className="rounded-0">
      <Card.Header
        className="fw-bold d-flex justify-content-between align-items-center cursor-pointer"
        onClick={() => {
          if (collapsed) {
            localStorage.removeItem(LOCAL_STORAGE_SETTINGS_COLLAPSED);
          } else {
            localStorage.setItem(LOCAL_STORAGE_SETTINGS_COLLAPSED, "1");
          }
          setCollapsed(!collapsed);
        }}
      >
        <BadgeWrapper>
          <div>
            <div>
              {userStatuses.map((userStatus) => (
                <Badge
                  key={userStatus.userAbbreviation}
                  bg={userStatus.isActive ? "success" : "warning"}
                  className="me-1"
                >
                  {userStatus.userAbbreviation}
                </Badge>
              ))}
            </div>
            <div>
              {gasPrices.map((gasPrice) => (
                <StyledBadge
                  key={gasPrice.networkPlatform}
                  bg="dark"
                  className="me-1"
                >
                  <span>{findNetworkById(gasPrice.networkPlatform)}</span>
                  <span>
                    <SizedSpan>B</SizedSpan> {gasPrice.baseFeePerGas.toFixed(1)}
                  </span>
                  <span>
                    <SizedSpan>P</SizedSpan>{" "}
                    {gasPrice.maxPriorityFeePerGas.toFixed(1)}
                  </span>
                  <span>
                    <SizedSpan>M</SizedSpan> {gasPrice.maxFeePerGas.toFixed(1)}
                  </span>
                </StyledBadge>
              ))}
            </div>
            {instanceTypeInfo.map((info) => {
              let statusColor;

              switch (info.status) {
                case InstanceTypeStatus.Running:
                  statusColor = "success";
                  break;
                case InstanceTypeStatus.Inactive:
                  statusColor = "warning";
                  break;
                case InstanceTypeStatus.Error:
                default:
                  statusColor = "danger";
                  break;
              }

              return (
                <Badge key={info.type} bg={statusColor} className="me-1">
                  {findInstanceById(info.type)}
                </Badge>
              );
            })}
            <div />
          </div>
        </BadgeWrapper>
        {collapsed ? <ChevronDown /> : <ChevronUp />}
      </Card.Header>

      {!collapsed && (
        <Card.Body className="p-2 p-md-3">
          <StyledButtonGroup>
            <Dropdown className="me-2 mb-2">
              <Dropdown.Toggle className="px-3" variant="secondary" size="sm">
                {datetimeSettingsLabel}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {dateOptions.map((button) => (
                  <Dropdown.Item
                    key={button.label}
                    onClick={() => {
                      setFromDate(
                        getIsoDate(
                          roundToNearestMinutes(button.onClickFromDate),
                        ),
                      );
                      setToDate(
                        getIsoDate(roundToNearestMinutes(button.onClickToDate)),
                      );
                      setDatetimeSettingsLabel(button.label);
                    }}
                  >
                    {button.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="me-2 mb-2">
              <Dropdown.Toggle className="px-3" variant="secondary" size="sm">
                {chosenWallet?.name || "Wallet"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {wallets?.map((wallet) => (
                  <Dropdown.Item
                    key={wallet.id}
                    onClick={() => {
                      setChosenWallet(wallet);
                    }}
                  >
                    {wallet.name}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => {
                    setChosenWallet(null);
                  }}
                >
                  All
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="me-2 mb-2">
              <Dropdown.Toggle className="px-3" variant="secondary" size="sm">
                {chosenArbitrage?.name || "Arbitrage"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {arbitrages?.map((arbitrage) => (
                  <Dropdown.Item
                    key={arbitrage.id}
                    onClick={() => setChosenArbitrage(arbitrage)}
                  >
                    {arbitrage.name}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setChosenArbitrage(null)}>
                  All
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </StyledButtonGroup>

          <Row sm={12}>
            <Col md={4} lg={3} className="mb-2">
              <InputGroup size="sm">
                <StyledInputGroupText>From</StyledInputGroupText>
                <Form.Control
                  type="datetime-local"
                  value={pickerFromDate}
                  onChange={(e) => setPickerFromDate(e.target.value)}
                  max={format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}
                />
              </InputGroup>
            </Col>
            <Col md={4} lg={3} className="mb-2 p-md-0">
              <InputGroup size="sm">
                <StyledInputGroupText>To</StyledInputGroupText>
                <Form.Control
                  type="datetime-local"
                  value={pickerToDate}
                  onChange={(e) => setPickerToDate(e.target.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row sm={12}>
            <Col md={4} lg={3} className="h-100">
              <Button
                size="sm"
                className="px-4"
                variant="success"
                onClick={() => {
                  setFromDate(pickerFromDate);
                  setToDate(pickerToDate);
                  setDatetimeSettingsLabel(DATETIME_SETTINGS_LABEL);
                }}
              >
                Apply
              </Button>
              <Button
                size="sm"
                className="px-4 mx-2"
                variant="danger"
                onClick={() => {
                  setFromDate(getIsoDate(startOfToday()));
                  setToDate(getIsoDate(startOfTomorrow()));
                  setDatetimeSettingsLabel(DATETIME_SETTINGS_LABEL);
                  setChosenWallet(null);
                  setChosenArbitrage(null);
                }}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Card.Body>
      )}
    </Card>
  );
};

export default Settings;
