import { useContext } from "react";

import Dashboard from "./Dashboard";
import Login from "./Login";
import { AuthContext } from "../context/Auth";
import { DataProvider } from "../context/Data";

const App = () => {
  const { isLogged } = useContext(AuthContext);

  return isLogged ? (
    <DataProvider>
      <Dashboard />
    </DataProvider>
  ) : (
    <Login />
  );
};

export default App;
