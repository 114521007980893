import { Alert } from "react-bootstrap";

import BalanceChart from "./BalanceChart";
import BalanceTable from "./BalanceTable";
import { useBalance } from "../../hooks";

const BalanceViewer = () => {
  const { balance, isLoading, hasError, countdown } = useBalance();

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }
  return (
    <>
      <BalanceTable
        balance={balance}
        countdown={countdown}
        isLoading={isLoading}
      />
      <BalanceChart balance={balance} isLoading={isLoading} />
    </>
  );
};

export default BalanceViewer;
