import { useEffect, useState } from "react";
import { Alert, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";

import useBaseGetHook from "../../hooks/useBaseGetHook";
import type {
  AssetResponse,
  CurrentBalanceGroupResponse,
  CurrentBalanceWalletResponse,
} from "../../types/Api";
import { floor } from "../../utils";
import Countdown from "../misc/Countdown";
import Loader from "../misc/Loader";
import { TableContainer } from "../misc/TableContainer";
import { RefreshIcon } from "../RefreshIcon";

const StyledTableContainer = styled(TableContainer)`
  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    background-color: #2e2e2e;
    z-index: 2;
  }

  td,
  th {
    box-shadow: 0px -1px 0 0 #444444;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    border-bottom: 1px solid #444444;
  }
`;

type WalletAssetRowProps = {
  asset: AssetResponse;
  wallets: CurrentBalanceWalletResponse[];
};

const WalletAssetRow = ({ asset, wallets }: WalletAssetRowProps) => (
  <tr key={`${asset.id}-${asset.name}`}>
    <td>{asset.name}</td>
    {wallets.map((wallet) => {
      const { balance } =
        wallet.assets.find(
          (walletAsset) => asset?.id === walletAsset.asset.id,
        ) || {};

      if (balance === null || balance === undefined) {
        return <td key={`${asset.id}-${wallet.wallet.id}`}>-</td>;
      }

      return (
        <td key={`${asset.id}-${wallet.wallet.id}`}>
          {balance?.total === 0
            ? 0
            : floor(balance?.total || 0, asset.textDecimals).toFixed(
                asset.textDecimals,
              )}
          {balance?.free === balance?.total
            ? null
            : ` (${floor(balance?.free || 0, asset.textDecimals).toFixed(
                asset.textDecimals,
              )})`}
        </td>
      );
    })}
  </tr>
);

type BalanceCurrentTabContentProps = {
  group: CurrentBalanceGroupResponse;
  fetchCurrentBalances: () => void;
};

const BalanceCurrentTabContent = ({
  group,
  fetchCurrentBalances,
}: BalanceCurrentTabContentProps) => (
  <StyledTableContainer
    isEmpty={false}
    header={[
      [<RefreshIcon key="refresh" onClick={fetchCurrentBalances} />, "90px"],
      ...group.wallets.map((item) => item.wallet.name),
    ]}
  >
    {group.assets.map(({ asset }) => (
      <WalletAssetRow
        key={`${asset.id}-${asset.name}`}
        asset={asset}
        wallets={group.wallets}
      />
    ))}
  </StyledTableContainer>
);

type BalanceCurrentProps = {
  currentBalanceGroups: CurrentBalanceGroupResponse[];
  fetchCurrentBalances: ReturnType<typeof useBaseGetHook>["fetchItems"];
  isLoading: boolean;
  hasError: string;
  countdown: number;
};

const BalanceCurrent = ({
  currentBalanceGroups,
  fetchCurrentBalances,
  isLoading,
  hasError,
  countdown,
}: BalanceCurrentProps) => {
  const [groupId, setGroupId] = useState<string>();

  useEffect(() => {
    fetchCurrentBalances();
  }, []);

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  if (!currentBalanceGroups.length) {
    return <Loader height="666px" />;
  }

  return (
    <>
      {countdown !== undefined && isLoading !== undefined && (
        <Countdown isLoading={isLoading} countdown={countdown} />
      )}
      <Tabs
        variant="pills"
        activeKey={groupId}
        onSelect={(k) => setGroupId(k || undefined)}
      >
        {currentBalanceGroups.map((group) => (
          <Tab eventKey={group.name} title={group.name} key={group.name}>
            <BalanceCurrentTabContent
              group={group}
              fetchCurrentBalances={fetchCurrentBalances}
            />
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default BalanceCurrent;
