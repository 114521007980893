import { useState } from "react";

import { TradeItemTransactionResponse } from "../../types/Api";
import { findOrderSideById } from "../../utils";
import { LinkMaybeModal } from "../misc/LinkMaybeModal";
import { LinksModal } from "../misc/LinksModal";
import { StyledTd } from "../misc/TableContainer";

type TransactionItemProps = {
  transaction: TradeItemTransactionResponse;
};

export const TransactionItem = ({ transaction }: TransactionItemProps) => {
  const [showLinksModal, setShowLinksModal] = useState(false);

  return (
    <>
      <tr key={transaction.transactionId}>
        <StyledTd>
          <LinkMaybeModal
            links={transaction.links}
            setShowLinksModal={setShowLinksModal}
          >
            {transaction.exchange.abbreviation}: {transaction.wallet.name}
          </LinkMaybeModal>
        </StyledTd>
        <StyledTd>{findOrderSideById(transaction.orderSide)}</StyledTd>
        <StyledTd>
          {transaction.assetPair.amountAsset.name}
          {transaction.assetPair.isEqualized ? "⌿" : "/"}
          {transaction.assetPair.priceAsset.name}
        </StyledTd>
        <StyledTd>
          {transaction.filled}/{transaction.amount}
        </StyledTd>
        <StyledTd>{transaction.status}%</StyledTd>
        <StyledTd>{transaction.averagePrice}</StyledTd>
        <StyledTd>{transaction.expectedPrice}</StyledTd>
        <StyledTd>{transaction.realPrice}</StyledTd>
        <StyledTd>{transaction.lastPrice || "-"}</StyledTd>
      </tr>
      <LinksModal
        links={transaction.links}
        showLinksModal={showLinksModal}
        setShowLinksModal={setShowLinksModal}
      />
    </>
  );
};
