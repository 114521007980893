import { useEffect } from "react";
import { Alert } from "react-bootstrap";

import NotificationConfiguration from "./NotificationConfiguration";
import { useNotificationConfiguration } from "../../hooks";
import Loader from "../misc/Loader";

const NotificationConfigurations = () => {
  const { fetchNotificationConfiguration, configuration, isLoading, hasError } =
    useNotificationConfiguration();

  useEffect(() => {
    fetchNotificationConfiguration();
  }, []);

  if (isLoading) return <Loader height="500px" />;
  if (hasError) return <Alert variant="danger">{hasError}</Alert>;

  return (
    <>
      {configuration?.map((config) => (
        <NotificationConfiguration key={config.id} config={config} />
      ))}
    </>
  );
};

export default NotificationConfigurations;
