import { useEffect, useState } from "react";

import { useCountdown } from "./useCountdownHook";
import { BACKEND_API_URL } from "../constants";

import { useFetch } from ".";

const useBaseGetHook = <T>(
  pathname?: string,
  isLoadingFallback?: boolean,
  refreshDataTimeout = 0,
  onFocusOnly = true,
) => {
  // @ts-ignore most of the time it is used as array, never used if value was not previously assigned
  const [items, setItems] = useState<T>([]);
  const [isLoading, setIsLoading] = useState<boolean>(
    isLoadingFallback ?? true,
  );
  const [hasError, setHasError] = useState<string>("");

  const { reset, countdown, setCountdown, stop } = useCountdown(
    refreshDataTimeout,
    onFocusOnly,
  );

  useEffect(() => {
    const fetchData = async () => {
      stop();
      await fetchItems();
      reset();
    };

    if (!!refreshDataTimeout && countdown === 0) {
      fetchData();
    }
  }, [countdown]);

  const { fetch, abortController } = useFetch();

  const fetchItems = async (pathnameFallback?: string) => {
    if (!pathname && !pathnameFallback) {
      console.error("Missing pathname");
      return;
    }

    const { signal } = abortController;

    setIsLoading(true);
    setHasError("");

    try {
      const result = await fetch(
        `${BACKEND_API_URL}/${pathname || pathnameFallback}`,
        { signal },
      );
      if (result.ok) {
        const parsedItems: T = await result.json();

        setItems(parsedItems);
      } else {
        const error = await result.json();

        setHasError(error?.message || `${pathname}`);
        // @ts-ignore
        setItems([]);
      }
    } catch (error) {
      setHasError(`/${pathname || pathnameFallback} - ${error}`);
      // @ts-ignore
      setItems([]);
    }
    setIsLoading(false);
    setCountdown(refreshDataTimeout);
  };

  useEffect(() => () => abortController.abort(), []);

  return {
    fetchItems,
    items,
    setItems,
    isLoading,
    hasError,
    countdown,
  };
};

export default useBaseGetHook;
