import { useEffect, useMemo } from "react";

import useBaseGetHook from "./useBaseGetHook";
import useBasePutHook from "./useBasePutHook";
import { LOCAL_STORAGE_PAGINATION } from "../constants";
import { PaginationResponseOfSwapResponse } from "../types/Api";

import { usePagination } from ".";

const useExchangeList = () => {
  const { pageNumber, setPageNumber } = usePagination();

  const params = useMemo(
    () =>
      new URLSearchParams({
        pageSize:
          localStorage.getItem(LOCAL_STORAGE_PAGINATION.exchange) || "20",
        pageNumber: pageNumber.toString(),
      }),
    [pageNumber],
  );

  const {
    items: exchangeList,
    isLoading,
    hasError,
    fetchItems: fetchExchangeList,
    countdown,
  } = useBaseGetHook<PaginationResponseOfSwapResponse>(
    `swap?${params.toString()}`,
    undefined,
    3,
  );

  useEffect(() => {
    fetchExchangeList();
  }, [pageNumber]);

  const {
    updateItem,
    id: cancelId,
    isLoading: cancelIsLoading,
    isLoadingDone: cancelIsDone,
    hasError: cancelHasError,
  } = useBasePutHook<null>();

  const cancelExchange = (id: number) =>
    updateItem(null, id, `swap/cancel/${id}`);

  return {
    fetchExchangeList,
    exchangeList,
    isLoading,
    hasError,
    cancelExchange,
    cancelId,
    cancelIsDone,
    cancelIsLoading,
    cancelHasError,
    countdown,
    pageNumber,
    setPageNumber,
  };
};

export default useExchangeList;
