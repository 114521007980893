import { format } from "date-fns";

import {
  ArbitrageTypeEnum,
  AssetPairResponse,
  AssetResponse,
  ExchangeTypeEnum,
  FeeAssetTypeEnum,
  InstanceTypeEnum,
  InstanceTypeStatusResponse,
  NetworkPlatformEnum,
  NetworkPlatformResponse,
  NotificationTypeEnum,
  OrderSideEnum,
  RoleEnum,
  SeverityTypeEnum,
  SwapWhitelistResponse,
  TradeDirectionEnum,
  WalletResponse,
  WithdrawalDepositStatusEnum,
} from "../types/Api";

export const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getIsoDate = (date: Date) => date.toISOString().replace("Z", "");

export const getTodayUTC = () => {
  const currentDateISOString = new Date().toISOString();
  const datePart = currentDateISOString.split("T")[0];
  return new Date(`${datePart}T00:00:00.000Z`);
};

export const getTimezoneOffset = () =>
  new Date().getTimezoneOffset() * 60 * 1000;

export const confirmDialog = (description: string) =>
  new Promise((resolve) => {
    const confirmed = window.confirm(description);

    return confirmed ? resolve(true) : resolve(false);
  });

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getOrderSide = (
  item?: SwapWhitelistResponse,
  assetA?: AssetResponse,
  assetB?: AssetResponse,
): OrderSideEnum | undefined => {
  if (!assetA || !assetB || !item) return;

  if (
    item.assetPairs.find(
      (asset) =>
        asset.priceAsset.id === assetA.id && asset.amountAsset.id === assetB.id,
    )
  ) {
    return OrderSideEnum.Buy;
  }

  if (
    item.assetPairs.find(
      (asset) =>
        asset.amountAsset.id === assetA.id && asset.priceAsset.id === assetB.id,
    )
  ) {
    return OrderSideEnum.Sell;
  }
};

export const getAssetPairId = (
  item?: SwapWhitelistResponse,
  assetA?: AssetResponse,
  assetB?: AssetResponse,
): AssetPairResponse | undefined => {
  if (!assetA || !assetB || !item) return;

  return item.assetPairs.find(
    (asset) =>
      (asset.priceAsset.id === assetA.id &&
        asset.amountAsset.id === assetB.id) ||
      (asset.priceAsset.id === assetB.id && asset.amountAsset.id === assetA.id),
  );
};

export const formatDateTime = (date: Date | string) =>
  format(new Date(date), "dd/MM HH:mm:ss");

export const findWalletById = (
  wallets: WalletResponse[],
  walletToFindById: WalletResponse["id"],
) => wallets.find((wallet) => wallet.id === walletToFindById);

export const findWalletByName = (
  wallets: WalletResponse[],
  walletToFindByName: WalletResponse["name"],
) => wallets.find((wallet) => wallet.name === walletToFindByName);

export const findNetworkById = (
  networkToFindById: NetworkPlatformResponse["id"],
) =>
  Object.keys(NetworkPlatformEnum)[
    Object.values(NetworkPlatformEnum).indexOf(networkToFindById)
  ];

export const findInstanceById = (
  instanceToFindById: InstanceTypeStatusResponse["type"],
) =>
  Object.keys(InstanceTypeEnum)[
    Object.values(InstanceTypeEnum).indexOf(instanceToFindById)
  ];

export const findTradeDirectionById = (id: TradeDirectionEnum) =>
  Object.keys(TradeDirectionEnum)[
    Object.values(TradeDirectionEnum).indexOf(id)
  ];

export const findOrderSideById = (id: OrderSideEnum) =>
  Object.keys(OrderSideEnum)[Object.values(OrderSideEnum).indexOf(id)];

export const findArbitrageTypeNameById = (id: ArbitrageTypeEnum) =>
  Object.keys(ArbitrageTypeEnum)[Object.values(ArbitrageTypeEnum).indexOf(id)];

export const findTimelineSeverityNameById = (id: SeverityTypeEnum) =>
  Object.keys(SeverityTypeEnum)[Object.values(SeverityTypeEnum).indexOf(id)];

export const findNotificationTypeNameById = (id: NotificationTypeEnum) =>
  Object.keys(NotificationTypeEnum)[
    Object.values(NotificationTypeEnum).indexOf(id)
  ];

export const findInstanceTypeTypeNameById = (id: InstanceTypeEnum) =>
  Object.keys(InstanceTypeEnum)[Object.values(InstanceTypeEnum).indexOf(id)];

export const findFeeAssetTypeNameById = (id: FeeAssetTypeEnum) =>
  Object.keys(FeeAssetTypeEnum)[Object.values(FeeAssetTypeEnum).indexOf(id)];

export const findWithdrawalDepositStatus = (id: WithdrawalDepositStatusEnum) =>
  Object.keys(WithdrawalDepositStatusEnum)[
    Object.values(WithdrawalDepositStatusEnum).indexOf(id)
  ];

export const findRoleById = (id: RoleEnum) =>
  Object.keys(RoleEnum)[Object.values(RoleEnum).indexOf(id)];

export const parseAmount = (amount: string) =>
  Number.parseFloat(amount.replace(",", "."));

export const getExchanges = () =>
  Object.entries(ExchangeTypeEnum)
    .filter(([_, word]) => isNaN(Number(word)))
    .map(([number, word]) => {
      const capitals = word.toString().replace(/[^A-Z]+/g, "");
      let abbreviation;
      if (capitals.length >= 2) {
        abbreviation = capitals;
      } else {
        abbreviation = word.toString().slice(0, 2).toUpperCase();
      }

      return {
        id: number as unknown as ExchangeTypeEnum,
        name: word,
        abbreviation,
      };
    });

export const findExchangeById = (id: ExchangeTypeEnum) =>
  //@ts-ignore
  getExchanges().find((exchange) => exchange?.id === id.toString());

export const isMobile = () => window.matchMedia("(max-width: 500px)").matches;

export const floor = (num: number, decimalPlaces = 0) => {
  const p = Math.pow(10, decimalPlaces);

  return Math.floor(num * p) / p;
};
