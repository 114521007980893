import { Fragment, useEffect } from "react";
import { Alert, Card, Form } from "react-bootstrap";

import AppConfiguration from "./AppConfiguration";
import { useAppConfiguration } from "../../hooks";
import Loader from "../misc/Loader";

const AppConfigurations = () => {
  const { configurations, fetchConfigurations, isLoading, hasError } =
    useAppConfiguration();

  useEffect(() => {
    fetchConfigurations();
  }, []);

  if (isLoading) return <Loader height="500px" />;
  if (hasError || !configurations) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between">
        Configuration
      </Card.Header>
      <Card.Body>
        <Form>
          {Object.entries(configurations).map(([key, configuration], index) => (
            <Fragment key={key}>
              <AppConfiguration config={configuration} />
              {index + 1 !== Object.keys(configurations).length && <hr />}
            </Fragment>
          ))}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AppConfigurations;
