import { ReactNode } from "react";
import Switch from "react-switch";

type ConfigurationTurnOnOffProps = {
  onChange: () => void;
  value: boolean;
  isModified?: boolean;
  checkedHandleLabel: string | ReactNode;
  uncheckedHandleLabel: string | ReactNode;
};

const ConfigurationTurnOnOff = ({
  onChange,
  value,
  isModified,
  checkedHandleLabel,
  uncheckedHandleLabel,
}: ConfigurationTurnOnOffProps) => (
  <div className="d-flex justify-content-center align-items-center ">
    <Switch
      onChange={onChange}
      checked={value}
      checkedIcon={<div />}
      uncheckedIcon={<div />}
      height={20}
      width={40}
      borderRadius={10}
      handleDiameter={26}
      onColor="#00bc8c"
      offColor="#eb3939"
      checkedHandleIcon={
        <div
          className={`d-flex justify-content-center align-items-center h-100 ${
            isModified ? "text-danger" : "text-dark"
          }`}
        >
          {checkedHandleLabel}
        </div>
      }
      uncheckedHandleIcon={
        <div
          className={`d-flex justify-content-center align-items-center h-100 ${
            isModified ? "text-danger" : "text-dark"
          }`}
        >
          {uncheckedHandleLabel}
        </div>
      }
    />
  </div>
);

export default ConfigurationTurnOnOff;
