import { Badge, Card } from "react-bootstrap";
import styled from "styled-components";

import { TransactionItem } from "./TransactionItem";
import { DOLLAR_DECIMALS } from "../../constants";
import { useWindowSize } from "../../hooks";
import {
  TradeDirectionEnum,
  TradeEvaluationEnum,
  TradeItemResponse,
} from "../../types/Api";
import { formatDateTime } from "../../utils";
import { TableContainer } from "../misc/TableContainer";

const getEvaluationColor = (evaluation: TradeEvaluationEnum) => {
  switch (evaluation) {
    case TradeEvaluationEnum.Bad:
      return "--bs-danger";
    case TradeEvaluationEnum.Good:
      return "--bs-warning";
    case TradeEvaluationEnum.Great:
      return "--bs-success";
  }
};

const StyledCard = styled(Card)<{ evaluation: TradeEvaluationEnum }>`
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    margin: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    background-color: var(${(props) => getEvaluationColor(props.evaluation)});
  }
`;

const InfoWrapper = styled.div<{ $windowWidth: number }>`
  min-width: ${({ $windowWidth }) =>
    ($windowWidth | 0) > 920 ? "250px" : "100%"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  margin-bottom: 2px;
`;

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-x: scroll;
`;

type TradeItemProps = {
  trade: TradeItemResponse;
};

export const TradeItem = ({ trade }: TradeItemProps) => {
  const { width: windowWidth } = useWindowSize();

  return (
    <StyledCard
      evaluation={trade.evaluation}
      border="light"
      className="text-white bg-secondary mb-2 text-center"
    >
      <Card.Body
        className={`px-0 py-0 d-flex ${
          (windowWidth || 0) > 920 ? "" : "flex-wrap"
        }`}
      >
        <InfoWrapper $windowWidth={windowWidth!}>
          <span>{formatDateTime(trade.created)}</span>
          <span className="fw-bold d-flex align-items-center">
            &nbsp;
            {trade.arbitrageName}&nbsp;
          </span>
          <h6 className="mb-1">
            &nbsp;
            <Badge bg="dark" text="white">
              {trade.tradeDirection === TradeDirectionEnum.Straight
                ? "➡️"
                : "⬅️"}{" "}
            </Badge>
            &nbsp;
            <Badge bg="dark" text="white">
              {trade.sourceExchange}
            </Badge>
            &nbsp;
            <Badge bg="dark" text="white">
              {trade.volume}$
            </Badge>
            &nbsp;
            <Badge bg="dark" text="white">
              {trade.configuredProfitInPercent}%
            </Badge>
            {trade.isMultiplierUsed && (
              <>
                &nbsp;
                <Badge bg="dark" text="white">
                  🔥
                </Badge>
              </>
            )}
            {!trade.isQuantityConsistent && (
              <>
                &nbsp;
                <Badge bg="dark" text="white">
                  ⚠️
                </Badge>
              </>
            )}
          </h6>
          <h5 className="mb-1">
            <Badge
              bg={trade.isProfitRealized ? "success" : "danger"}
              text="white"
            >
              {trade.realProfitInDollars.toFixed(DOLLAR_DECIMALS)}$
            </Badge>
            &nbsp;
            <Badge bg="dark" text="white" className="fst-italic fw-lighter">
              {trade.expectedProfitInPercent}%
            </Badge>
            &nbsp;
            <Badge
              bg={trade.isExpectedProfitRealized ? "success" : "danger"}
              text="white"
            >
              {trade.realProfitInPercent}%
            </Badge>
            &nbsp;
          </h5>
        </InfoWrapper>
        <TableWrapper>
          <TableContainer
            bordered={false}
            striped={false}
            hover={false}
            wrapperClassName="mt-0"
            tableClassName="mx-2 my-0 mx-lg-0"
            header={[
              ["Wallet", "100px"],
              ["Side", "55px"],
              ["Pair", "120px"],
              ["Filled", "230px"],
              ["Status", "70px"],
              ["Average", "130px"],
              ["Expected", "130px"],
              ["Real", "130px"],
              ["Order price", "130px"],
            ]}
            isEmpty={false}
          >
            {trade.transactions.map((transaction) => (
              <TransactionItem
                key={transaction.transactionId}
                transaction={transaction}
              />
            ))}
          </TableContainer>
        </TableWrapper>
      </Card.Body>
    </StyledCard>
  );
};
