import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import AppConfigurations from "./AppConfigurations";
import { ArbitrageInfo } from "./ArbitrageInfo";
import NotificationConfigurations from "./NotificationConfigurations";
import TradeConfigurations from "./TradeConfigurations";
import UsersConfiguration from "./UsersConfiguration";

enum TabsName {
  App = "App",
  Info = "Info",
  Notification = "Notification",
  Trade = "Trade",
  Users = "Users",
}

const ConfigurationViewer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [key, setKey] = useState<string>(
    pathname === "/configuration" ? `${pathname}/${TabsName.Trade}` : pathname,
  );

  return (
    <>
      <Tabs
        variant="pills"
        className="mb-1 bg-transparent"
        activeKey={key}
        onSelect={(k) => {
          if (!k) return;

          setKey(k);
          navigate(k);
        }}
      >
        <Tab
          eventKey={`/configuration/${TabsName.Trade}`}
          title={TabsName.Trade}
          key={TabsName.Trade}
        />
        <Tab
          eventKey={`/configuration/${TabsName.Notification}`}
          title={TabsName.Notification}
          key={TabsName.Notification}
        />
        <Tab
          eventKey={`/configuration/${TabsName.App}`}
          title={TabsName.App}
          key={TabsName.App}
        />
        <Tab
          eventKey={`/configuration/${TabsName.Info}`}
          title={TabsName.Info}
          key={TabsName.Info}
        />
        <Tab
          eventKey={`/configuration/${TabsName.Users}`}
          title={TabsName.Users}
          key={TabsName.Users}
        />
      </Tabs>
      <Routes>
        <Route index element={<TradeConfigurations />} />
        <Route path="trade" element={<TradeConfigurations />} />
        <Route path="notification" element={<NotificationConfigurations />} />
        <Route path="app" element={<AppConfigurations />} />
        <Route path="info" element={<ArbitrageInfo />} />
        <Route path="users" element={<UsersConfiguration />} />
      </Routes>
    </>
  );
};

export default ConfigurationViewer;
