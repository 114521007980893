import { useContext } from "react";
import { Alert, Container, Spinner } from "react-bootstrap";
import styled from "styled-components";

import Settings from "./settings/Settings";
import TabsNav from "./TabsNav";
import { DataContext } from "../context/Data";

const StyledSpinnerBox = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledSpinner = styled(Spinner)`
  width: 80px;
  height: 80px;
`;

const Dashboard = () => {
  const { dataIsLoading, dataHasError } = useContext(DataContext);

  if (dataIsLoading) {
    return (
      <StyledSpinnerBox>
        <StyledSpinner size="sm" animation="border" variant="primary" />
      </StyledSpinnerBox>
    );
  }

  if (dataHasError.length) {
    return (
      <Alert variant="danger">
        <Alert.Heading>Errors list:</Alert.Heading>
        {dataHasError.map((error) => (
          <p key={error}>{error}</p>
        ))}
      </Alert>
    );
  }

  return (
    <Container fluid className="p-1 p-sm-3">
      <Settings />
      <TabsNav />
    </Container>
  );
};

export default Dashboard;
