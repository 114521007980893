import { useContext, useEffect } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";

import { LOCAL_STORAGE_PAGINATION } from "../../constants";
import { DataContext } from "../../context/Data";
import { useBlockHeight, useTransferList } from "../../hooks";
import {
  BlockHeightResponse,
  WithdrawalDepositStatusEnum,
  WithdrawalResponse,
} from "../../types/Api";
import {
  findNetworkById,
  findWalletById,
  findWithdrawalDepositStatus,
  formatDateTime,
} from "../../utils";
import { StyledTd, TableContainer } from "../misc/TableContainer";

const getTransferStatus = (
  status: WithdrawalResponse["deposit"]["statusId"],
  depositWalletName: string,
  asset: string,
  block?: number | null,
  currentBlock?: BlockHeightResponse | null,
) => {
  if (
    (depositWalletName === "Binance" || depositWalletName === "Kucoin") &&
    asset === "WAVES" &&
    block &&
    currentBlock
  ) {
    if (currentBlock && currentBlock.blockHeight - block <= 25) {
      return `${findWithdrawalDepositStatus(
        WithdrawalDepositStatusEnum.Processing,
      )} ${currentBlock.blockHeight - block}/25`;
    }

    if (
      currentBlock.blockHeight - block <= 90 &&
      depositWalletName === "Binance"
    ) {
      return `${findWithdrawalDepositStatus(
        WithdrawalDepositStatusEnum.Completed,
      )} ${currentBlock.blockHeight - block}/90`;
    }
  }

  return findWithdrawalDepositStatus(status!);
};

const isDepositCompleted = (transfer: WithdrawalResponse) =>
  transfer.deposit.statusId === WithdrawalDepositStatusEnum.Completed &&
  transfer.statusId === WithdrawalDepositStatusEnum.Completed;

const TransferTable = () => {
  const { wallets } = useContext(DataContext);
  const { height, fetchBlockHeight } = useBlockHeight();

  const {
    transferList,
    isLoading,
    hasError,
    updateTransfer,
    updateId,
    updateIsLoading,
    updateHasError,
    updateIsDone,
    countdown,
    pageNumber,
    setPageNumber,
  } = useTransferList();

  useEffect(() => {
    fetchBlockHeight();
  }, []);

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  return (
    <>
      <TableContainer
        isLoading={isLoading}
        countdown={countdown}
        header={[
          "Date & Time",
          "Author",
          "Asset",
          "Amount",
          "Source",
          "Destination",
          "Network",
          "Fee $",
          "Status",
          ["Actions", "150px"],
        ]}
        isEmpty={!transferList?.items?.length}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        totalPages={transferList.totalPages}
        currentPageNumber={transferList.pageNumber}
        paginationName={LOCAL_STORAGE_PAGINATION.transfer}
      >
        {transferList?.items?.map((transfer) => (
          <tr key={transfer.id}>
            <StyledTd>{formatDateTime(transfer.created)}</StyledTd>
            <StyledTd>{transfer.createdBy}</StyledTd>
            <StyledTd>{transfer.asset.name}</StyledTd>
            <StyledTd>{transfer.amount}</StyledTd>
            <StyledTd>{transfer.wallet.name}</StyledTd>
            <StyledTd>
              {findWalletById(wallets, transfer.deposit.walletId)?.name}
            </StyledTd>
            <StyledTd>{findNetworkById(transfer.networkPlatformId)}</StyledTd>
            <StyledTd>{transfer.feeInDollars?.toFixed(2)}</StyledTd>
            <StyledTd>
              <a href={transfer.link || ""} target="_blank" rel="noreferrer">
                {getTransferStatus(
                  Math.min(
                    transfer.deposit.statusId ||
                      WithdrawalDepositStatusEnum.Processing,
                    transfer.statusId || WithdrawalDepositStatusEnum.Processing,
                  ),
                  transfer.deposit.walletName,
                  transfer.asset.id,
                  transfer.blockHeight,
                  height,
                )}
              </a>
            </StyledTd>
            <StyledTd>
              {!isDepositCompleted(transfer) && (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() =>
                    updateTransfer({
                      id: transfer.id,
                      withdrawalStatusId: WithdrawalDepositStatusEnum.Completed,
                      depositStatusId: WithdrawalDepositStatusEnum.Completed,
                    })
                  }
                >
                  Complete{" "}
                  {updateId === transfer.id && updateIsLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                    />
                  )}
                </Button>
              )}
            </StyledTd>
          </tr>
        ))}
      </TableContainer>

      {updateIsDone && (
        <Alert className="mt-2" variant="success">
          Done
        </Alert>
      )}
      {updateHasError && (
        <Alert className="mt-2" variant="danger">
          Id {updateId} {updateHasError}
        </Alert>
      )}
    </>
  );
};

export default TransferTable;
