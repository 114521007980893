import useBaseGetHook from "./useBaseGetHook";
import useBasePutHook from "./useBasePutHook";
import {
  ArbitrageResponse,
  ConfigurationRequest,
  ConfigurationResponse,
} from "../types/Api";
import { parseAmount } from "../utils";

const formatDataBeforeUpdate = (configuration: Record<string, string>) =>
  Object.keys(configuration).reduce(
    (acc, key) => {
      const value = configuration[key];
      const parsedValue = parseFloat(value);
      if (!Number.isNaN(parsedValue) && typeof value === "string") {
        acc[key] = parseAmount(value);
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, string | number>,
  );

const useConfiguration = () => {
  const {
    items: configurations,
    isLoading,
    hasError,
    fetchItems: fetchConfigurations,
  } = useBaseGetHook<ConfigurationResponse[]>("configuration");

  const {
    updateItem,
    isLoading: updateIsLoading,
    isLoadingDone: updateIsDone,
    hasError: updateHasError,
  } = useBasePutHook<ConfigurationRequest>();

  const updateConfiguration = (data: ArbitrageResponse) => {
    const formattedConfiguration = formatDataBeforeUpdate(data.configuration);

    const formattedAssetPairWallets = data.assetPairWallets.map(
      (assetPairWallet) => ({
        id: assetPairWallet.id,
        configuration: formatDataBeforeUpdate(assetPairWallet.configuration),
      }),
    );

    const dataToUpdate = {
      id: data.id,
      configuration: formattedConfiguration,
      isPaused: data.isPaused,
      isEnabled: data.isEnabled,
      assetPairConfigurations: formattedAssetPairWallets,
    };

    updateItem(dataToUpdate, undefined, `configuration/${data.id}`);
  };

  return {
    configurations,
    fetchConfigurations,
    isLoading,
    hasError,
    updateConfiguration,
    updateIsLoading,
    updateHasError,
    updateIsDone,
  };
};

export default useConfiguration;
