import { useContext, useEffect, useMemo } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { DataContext } from "../context/Data";
import { ArbitragePerformanceResponse } from "../types/Api";

const usePerformanceStoreHook = () => {
  const { fromDate, toDate } = useContext(DataContext);

  const params = useMemo(
    () =>
      new URLSearchParams({
        from: fromDate,
        to: toDate,
      }),
    [fromDate, toDate],
  );

  const {
    items: performanceStore,
    isLoading,
    hasError,
    fetchItems: fetchPerformanceStore,
    countdown,
  } = useBaseGetHook<ArbitragePerformanceResponse[]>(
    `arbitrage/performance?${params.toString()}`,
    undefined,
    5,
  );

  useEffect(() => {
    fetchPerformanceStore();
  }, [fromDate, toDate]);

  return {
    performanceStore,
    isLoading,
    hasError,
    countdown,
    fetchPerformanceStore,
  };
};

export default usePerformanceStoreHook;
