import { useEffect, useMemo } from "react";

import useBaseGetHook from "./useBaseGetHook";
import useBasePutHook from "./useBasePutHook";
import { LOCAL_STORAGE_PAGINATION } from "../constants";
import type {
  PaginationResponseOfWithdrawalResponse,
  UpdateWithdrawalRequest,
} from "../types/Api";

import { usePagination } from ".";

const useTransferList = () => {
  const { pageNumber, setPageNumber } = usePagination();

  const params = useMemo(
    () =>
      new URLSearchParams({
        pageSize:
          localStorage.getItem(LOCAL_STORAGE_PAGINATION.transfer) || "20",
        pageNumber: pageNumber.toString(),
      }),
    [pageNumber],
  );

  const {
    items: transferList,
    isLoading,
    hasError,
    fetchItems: fetchTransferList,
    countdown,
  } = useBaseGetHook<PaginationResponseOfWithdrawalResponse>(
    `withdrawal?${params.toString()}`,
    undefined,
    3,
  );

  useEffect(() => {
    fetchTransferList();
  }, [pageNumber]);

  const {
    updateItem,
    id: updateId,
    isLoading: updateIsLoading,
    isLoadingDone: updateIsDone,
    hasError: updateHasError,
  } = useBasePutHook<UpdateWithdrawalRequest>();

  const updateTransfer = (data: UpdateWithdrawalRequest) =>
    updateItem(data, data.id, `withdrawal/${data.id}`);

  return {
    fetchTransferList,
    transferList,
    isLoading,
    hasError,
    updateTransfer,
    updateId,
    updateIsLoading,
    updateHasError,
    updateIsDone,
    countdown,
    pageNumber,
    setPageNumber,
  };
};

export default useTransferList;
