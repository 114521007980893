import {
  Chart as ChartJS,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  registerables,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom";

import "chartjs-adapter-date-fns";
import "./public/bootstrap.min.css";

import App from "./components/App";
import { AuthProvider } from "./context/Auth";

ChartJS.register(
  zoomPlugin,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  ...registerables,
);

const Index = () => (
  <HashRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </HashRouter>
);

const root = createRoot(document.getElementById("root")!);
root.render(<Index />);
