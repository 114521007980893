import styled from "styled-components";

const StyledA = styled.a<{ $isLink: boolean }>`
  color: ${(props) => (props.$isLink ? "var(--bs-success)" : "inherit")};
  text-decoration: ${(props) => (props.$isLink ? "underline" : "none")};
  cursor: ${(props) => (props.$isLink ? "pointer" : "inherit")};

  &:hover {
    color: ${(props) => (props.$isLink ? "#009670" : "inherit")};
  }
`;

interface LinkMaybeModalProps {
  links: string[];
  children: React.ReactNode;
  setShowLinksModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LinkMaybeModal = ({
  links,
  children,
  setShowLinksModal,
}: LinkMaybeModalProps) => (
  <StyledA
    $isLink={!!links.length}
    href={links.length === 1 ? links[0] : undefined}
    onClick={links.length > 1 ? () => setShowLinksModal(true) : undefined}
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </StyledA>
);
