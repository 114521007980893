import { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import ConfigurationFormBottom from "./ConfigurationFormBottom";
import { useAppConfiguration } from "../../hooks";
import type {
  ApplicationConfigurationItemOfBoolean,
  ApplicationConfigurationItemOfDecimal,
} from "../../types/Api";
import { confirmDialog, parseAmount } from "../../utils";
import ConfigurationInput from "../misc/ConfigurationInput";
import ConfigurationSwitch from "../misc/ConfigurationSwitch";

type AppConfigurationProps = {
  config:
    | ApplicationConfigurationItemOfBoolean
    | ApplicationConfigurationItemOfDecimal;
};

const AppConfiguration = ({ config }: AppConfigurationProps) => {
  const [previousConfig, setPreviousConfig] = useState(config);

  const { updateConfiguration, updateIsLoading, updateHasError, updateIsDone } =
    useAppConfiguration();

  const [configuration, setConfiguration] = useState(config);

  useEffect(() => {
    if (updateIsDone) {
      setPreviousConfig({ ...configuration });
    }
  }, [updateIsDone]);

  const isModified = configuration.value != previousConfig.value;

  return (
    <Fragment key={configuration.key}>
      <Form.Group className="mb-3" controlId={`${configuration.key}`}>
        {typeof configuration.value === "boolean" && (
          <ConfigurationSwitch
            label={configuration.key!}
            value={configuration.value}
            isModified={isModified}
            onChange={(e) =>
              setConfiguration({ ...configuration, value: e.target.checked })
            }
          />
        )}
        {(typeof configuration.value === "number" ||
          typeof configuration.value === "string") && (
          <ConfigurationInput
            isModified={isModified}
            label={configuration.key!}
            value={configuration.value}
            onChange={(e) =>
              setConfiguration({
                ...configuration,
                // @ts-ignore
                value: e.target.value,
              })
            }
            button="-"
            buttonOnClick={() =>
              setConfiguration({
                ...configuration,
                // @ts-ignore
                value: "-",
              })
            }
          />
        )}
      </Form.Group>
      <ConfigurationFormBottom
        variant="danger"
        updateHasError={updateHasError}
        updateIsLoading={updateIsLoading}
        updateIsDone={updateIsDone}
        onClick={async () => {
          const updateConfig = {
            id: configuration.key || "",
            value: configuration.value,
          };

          if (typeof configuration.value === "string") {
            // @ts-ignore
            updateConfig.value = parseAmount(configuration.value);
          }

          const isConfirmed = await confirmDialog(
            `Do you really want to change ${updateConfig.id} to ${updateConfig.value}?`,
          );

          if (isConfirmed) {
            updateConfiguration(updateConfig);
          }
        }}
      />
    </Fragment>
  );
};

export default AppConfiguration;
