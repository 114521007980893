import { Alert } from "react-bootstrap";

import { LOCAL_STORAGE_PAGINATION } from "../../constants";
import { useTimeline } from "../../hooks";
import {
  findNotificationTypeNameById,
  formatDateTime,
  isMobile,
} from "../../utils";
import { TableContainer } from "../misc/TableContainer";

const TimelineViewer = () => {
  const {
    timelineData,
    isLoading,
    hasError,
    countdown,
    pageNumber,
    setPageNumber,
  } = useTimeline();

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  return (
    <TableContainer
      isLoading={isLoading}
      countdown={countdown}
      header={[
        ["Created", "130px"],
        !isMobile() && ["Type", "230px"],
        ["Info", "600px"],
      ]}
      isEmpty={!timelineData?.items?.length}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      totalPages={timelineData.totalPages}
      currentPageNumber={timelineData.pageNumber}
      paginationName={LOCAL_STORAGE_PAGINATION.timeline}
    >
      {timelineData?.items?.map((timeline, index) => (
        <tr key={index}>
          <td>{formatDateTime(timeline.created!)}</td>
          {!isMobile() && (
            <td>{findNotificationTypeNameById(timeline.type)}</td>
          )}
          <td>{timeline.text}</td>
        </tr>
      ))}
    </TableContainer>
  );
};

export default TimelineViewer;
