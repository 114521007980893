import { useEffect } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { InstanceTypeStatusResponse } from "../types/Api";

const useInstanceTypeStatus = () => {
  const {
    items: instanceTypeInfo,
    isLoading,
    hasError,
    fetchItems: fetchInstanceTypeStatus,
    countdown,
  } = useBaseGetHook<InstanceTypeStatusResponse[]>(
    `instancetype/status`,
    undefined,
    5,
  );

  useEffect(() => {
    fetchInstanceTypeStatus();
  }, []);

  return {
    instanceTypeInfo,
    isLoading,
    hasError,
    countdown,
  };
};

export default useInstanceTypeStatus;
