import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import styled from "styled-components";

import { isArbitrageResponse } from "./configUtils";
import {
  ArbitrageAssetPairWalletResponse,
  ArbitrageResponse,
} from "../../types/Api";
import { findFeeAssetTypeNameById } from "../../utils";

const StyledHeading = styled.div`
  cursor: pointer;
  width: 100%;
`;

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  margin-left: 4px;
`;

interface TradeConfigurationHeaderProps {
  arbitrage: ArbitrageResponse | ArbitrageAssetPairWalletResponse;
  isVisible: boolean;
  handleVisibilityChange: () => void;
}

export const TradeConfigurationHeader = ({
  arbitrage,
  isVisible,
  handleVisibilityChange,
}: TradeConfigurationHeaderProps) => (
  <StyledHeading
    onClick={handleVisibilityChange}
    className="d-flex align-items-center"
  >
    {isVisible ? (
      <ChevronUp className="mx-1" />
    ) : (
      <ChevronDown className="mx-1" />
    )}
    {isArbitrageResponse(arbitrage) ? (
      <Wrapper>
        <span>{arbitrage.name}</span>
      </Wrapper>
    ) : (
      `${arbitrage.wallet.name}: ${arbitrage.assetPair.amountAsset.name}${
        arbitrage.assetPair.isEqualized ? "⌿" : "/"
      }${arbitrage.assetPair.priceAsset.name} (${findFeeAssetTypeNameById(
        arbitrage.feeType,
      )})${arbitrage.eventTrigger ? "" : " 🛑"}`
    )}
  </StyledHeading>
);
