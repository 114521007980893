import useBaseGetHook from "./useBaseGetHook";
import { BlockHeightResponse, ExchangeTypeEnum } from "../types/Api";

const useBlockHeight = () => {
  const params = new URLSearchParams({
    exchangeType: ExchangeTypeEnum.WavesExchange.toString(),
  });

  const {
    items: height,
    isLoading,
    hasError,
    fetchItems: fetchBlockHeight,
  } = useBaseGetHook<BlockHeightResponse>(
    `node/blockheight?${params.toString()}`,
    undefined,
    3,
  );

  return { height, isLoading, hasError, fetchBlockHeight };
};

export default useBlockHeight;
