import useBaseGetHook from "./useBaseGetHook";
import { WalletBalanceResponse } from "../types/Api";

const useWalletBalanceHook = () => {
  const {
    items: walletBalances,
    isLoading,
    hasError,
    fetchItems,
  } = useBaseGetHook<WalletBalanceResponse[]>(undefined, false);

  const fetchWalletBalances = async (walletId: number) => {
    return fetchItems(`wallet/${walletId}/balance`);
  };

  return { walletBalances, fetchWalletBalances, isLoading, hasError };
};

export default useWalletBalanceHook;
